import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/BannerIrbis"
import { Banner as RoundedDynamicBanner } from "../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { FormPKW } from "../components/FormPKW"
import { Partners } from "../components/PartnersNew"
import { NewFooter } from "../components/NewFooter"
import { getPageData } from "../helpers/getPageData"

import { BannerText, getDynamicBannerText } from "../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../helpers/getDynamicBannerUrl"

import { PageData } from "../interfaces/pageProps"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { partnersIrbis } from "../components/PartnersNew/helpers"
import { Questions } from "../components/Questions"
import { MoreBenefitsInvitefriend } from "../components/MoreBenefitsInvitefriend"
import { BottomCta, RefinSeoBgImg } from "../components/BottomCta"
import { QUESTIONS_FOR_IRBIS } from "../components/Questions/helpers"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const bannerTitle = `
Оформите Халву и&nbsp;участвуйте в&nbsp;акции
`

const bannerDescription = `
Получите кэшбэк 1 500 на&nbsp;первую покупку
`
const subTitlePartners = <>Любые покупки у партнеров в&nbsp;рассрочку</>

// Время для таймер(30мин)
const countDownTime = 1800000

const GIFT_TITLE = <>Выиграйте годовой запас бензина и&nbsp;кэшбэк 30000 руб.</>

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [data.allDynamicBanner, bannerImageUrl])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout
      seoData={{
        title: "Акция с АЗС IRBIS - получите 1500 баллов кэшбэка на карту Халва за первую покупку",
        description:
          "Совершите первую покупку на АЗС IRBIS от 1500 рублей и получите 1500 баллов кэшбэка на карту «Халва»",
      }}
      noHeader
      noFooter
      noIndex={noIndex}
    >
      <NewHeader
        showHint
        countDownTime={countDownTime}
        hintTitle={
          <>
            Участвуй в<br /> розыгрыше
          </>
        }
      />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="2"
        />
      ) : (
        <Banner
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          orderNum="2"
        />
      )}
      <MoreBenefitsInvitefriend variant="irbis" orderNum="2" />
      <FormPKW
        progressBar
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="3"
        onlyPKWPage
        isGreenApprove
      />
      <FourAdvantagesPoints
        withTitle
        indent
        variant="pkwRedesign"
        title="Преимущества с Халвой:"
        orderNum="4"
      />
      <BottomCta
        buttonText="Подробнее"
        variant="pkwRedesign"
        title={GIFT_TITLE}
        BgImg={RefinSeoBgImg}
        orderNum="5"
      />
      <Partners
        title="Более 260&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="8"
        additionalPartners={partnersIrbis}
      />
      <Questions questionList={QUESTIONS_FOR_IRBIS} orderNum="7" />
      <NewFooter ligal={ligal} orderNum="8" />
    </Layout>
  )
}
export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/irbis/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
